.sidebar-work-card {

  .form-label {
    color: var(--vz-body-color)
  }

  .value-cell {
    position: relative;
    padding: 0.25rem;

    input {
      padding: 0.25rem;
      text-align: center;

      &::placeholder {
        text-align: center;
      }
    }

    &.is-updating {
      @include input-loader-element;

      input {
        @include cell-loading-style;
        pointer-events: none;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  .field.is-updating {

    .number-input-wrapper {
      @include input-loader-element;
      position: relative;

      input {
        @include cell-loading-style;
        pointer-events: none;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }
}