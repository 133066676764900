.custom-select {
  position: relative;

  .dropdown.without-padding {
    padding: 0 !important;
  }
}

body {
  .form-control.is-valid {
    border-color: $input-border-color;
    padding-right: 0.9rem;
    background-image: unset;
    background-size: unset;
    background-position: unset;

    &:focus {
      border-color: $input-border-color;
      box-shadow: unset;
    }
  }

  .form-check-input.is-valid {
    border-color: $input-border-color;

    & ~ .form-check-label {
      color: inherit;
    }
  }

  .form-control.is-valid:focus {
    border-color: $input-border-color;
    box-shadow: unset;
  }

  .invalid-feedback {
    display: block;
  }
}