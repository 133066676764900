.data-fetch-progres {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 9999999999;

  .loading-anim {
    color: #1a1d21;
    font-size: 28px;
  }

  .progress-bar {
    font-size: 16px;
    color: #fff;
  }
}