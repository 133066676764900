.tasks-histories {

  .history-timeline {

    .history-item-title {
      cursor: pointer;

      &:hover {
        color: var(--vz-link-hover-color);
      }
    }
  }
}