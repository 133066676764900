.performed-table-date {
  font-size: $performed-table-date-font-size;
  margin-right: 14px;

  i {
    margin-left: 4px;
  }
}

.performed-table-container {

  .overflow-auto {

    table {
      position: relative;

      tr {

        td,
        th {
          position: relative;
          padding: $performed-table-cell-padding;

          .number-input-wrapper,
          .text-input-wrapper {

            input {
              padding: $performed-table-input-padding;
            }
          }

          .text-input-wrapper {

            input {
              width: $performed-table-textarea-width
            }
          }

          &.is-updating {

            .number-input-wrapper,
            .text-input-wrapper {
              position: relative;

              input {
                @include cell-loading-style();
              }

              @include input-loader-element();
            }
          }
        }

        td.sticky {
          @include custom-border-right();
        }

        th.sticky,
        td.sticky {
          border-right: 0;
          border-left: 0;

          &:nth-child(1) .cell-extender {
            min-width: 120px;
          }

          &:nth-child(2) .cell-extender {
            min-width: 104px;
          }

          &:nth-child(3) .cell-extender {
            min-width: 160px;
          }

          &:nth-child(4) .cell-extender {
            min-width: 104px;
          }

          &:nth-child(5) .cell-extender {
            min-width: 100px;
          }

          .cell-extender {
            padding-left: 10px;
            padding-right: 10px;
          }

          &:nth-child(5) {

            &::after {
              width: 2px !important;
            }
          }
        }

        th.sticky {

          &:nth-child(5) {

            @include custom-border-right();
          }
        }
      }

      tfoot {

        tr {

          td {
            border-bottom: 0;

            &.sticky {
              left: 0;

              &::after {
                width: 2px !important;
              }
            }
          }
        }
      }
    }

    @include custom-scrollbar-style(false)
  }
}