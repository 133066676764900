.notification-body {
  max-height: 300px
}

.notification-box {
  position: relative;
}

.notification-box-wrapper {
  top: calc(100% + 2px) !important;
  right: 0;
}