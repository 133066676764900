.card.schedule {

  .card-header {

    .month-picker-container {
      display: inline-block;

      .month-picker-value {
        cursor: pointer;
      }

      .month-picker__container {
        z-index: 2;
      }
    }
  }

  .custom-select.absence-select {
    .form-control.is-invalid {
      background-image: unset;
    }

    .invalid-feedback {
      display: none;
    }
  }
}

.timekeeping-form .overflow-auto,
.absences-table .overflow-auto,
.sidebar-work-card .overflow-auto {
  @include custom-scrollbar-style(false);
}

.schedule-title {
  line-height: $schedule-title-line-height;
  font-weight: $schedule-title-font-weight;
  color: $schedule-title-color;
  font-family: $schedule-title-font;
  margin-bottom: 0;
}