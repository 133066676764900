input.flat-pickr-time-input {
  border: 1px solid $time-input-border-color;
  border-radius: $time-input-border-radius;

  &::placeholder {
    color: $time-input-placeholder-color;
  }

  &.form-control {
    display: inline-block;
    padding: 0 2px;

    &.is-invalid {
      background-image: unset;
    }
  }
}