//
// _choices.scss
//
.multiselect-tag {
    display: inline-block;
    vertical-align: initial !important;
    border-radius: 7px !important;
    padding: 2px 7px !important;
    font-size: 11px !important;
    font-weight: $font-weight-normal !important;
    margin-right: 3.75px !important;
    margin-bottom: 3.75px !important;
    margin-top: 2px !important;
    background-color: $primary !important;
    border: 1px solid $primary !important;
    word-break: break-all;
    box-sizing: border-box;
    color: $white;
    box-shadow: $element-shadow;
}

.multiselect {
    font-size: $input-font-size !important;
    &.is-active {
        border-color: $input-focus-border-color;
        box-shadow: none !important;
    }
}

.multiselect-dropdown {
    background-color: $dropdown-bg !important;
    border: 1px solid $dropdown-border-color !important;
    box-shadow: $dropdown-box-shadow;
    padding: 0px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
}

.multiselect-option {
    background-color: $form-select-bg !important;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x $dropdown-item-padding-y 16px;
    font-size: $input-font-size !important;
    font-family: $font-family-primary;
    &.is-pointed {
        background-color: $dropdown-link-hover-bg !important;
        color: $input-color !important;
    }
    &.is-selected {
        color: $input-color !important;
    }
}


[data-layout-mode="dark"] {
    .choices[data-type*=select-one] {
        .choices__button {
            filter: $btn-close-white-filter;
        }
    }
}