.main-content .modal-dialog-bg {
  position: fixed;
  top: 117px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: $popup-bg-color;
  justify-content: center;
  display: none;

  &.active {
    display: flex;
  }

  .modal-dialog {
    margin-top: 0;

    .modal-content {
      border-radius: 0 0 5px 5px;

      .warning-description {
        font-size: $warning-modal-font-size;
      }
    }

    .modal-header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ccc;
    }
  }

  .loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    padding: 20px;

    justify-content: center;
    align-items: center;
  }
}

