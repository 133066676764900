.project-schedules {

  .project-schedule-table {

    tbody {

      tr:last-child {

        border-bottom-color: var(--vz-border-color);
      }
    }
  }

  .field {

    &.col-md-12 {
      margin-bottom: 0!important;
    }

    .number-input-wrapper {

      .form-control {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &.is-updating {

      .number-input-wrapper {
        @include input-loader-element;
        position: relative;

        input {
          @include cell-loading-style;
          pointer-events: none;

          &::placeholder {
            color: transparent;
          }
        }
      }
    }
  }
}
