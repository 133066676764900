.profile-data-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    flex: 0 0 50%;
    margin-bottom: $profile-label-margin-bottom;

    .label {
      flex: 0 0 $profile-label-basis;
      font-weight: $profile-label-font-weight;
      margin-right: $profile-label-margin-right;
    }
  }
}