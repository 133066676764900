.project-data-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    flex: 0 0 33%;
    margin-bottom: $project-label-margin-bottom;

    .label {
      flex: 0 0 $project-label-basis;
      font-weight: $project-label-font-weight;
      margin-right: $project-label-margin-right;
    }
  }
}