table.table-skeleton-loading {
  width: 100%;
  overflow: hidden;

  th {
    padding:10px 5px;
    border-color: $table-skeleton-cell-border-color;
    background-color:#f0f0f0;
  }

  td {
    padding: 10px 5px;
  }

  th > div,
  td > div {
    width: 100px;
    height: 16px;
    margin: 6px auto;
    border-radius: 7px;

    background-image: linear-gradient(90deg, $table-skeleton-cell-bg-color 0px, $table-skeleton-cell-shine-bg-color 40px, $table-skeleton-cell-bg-color 80px);
    background-size: 600px;

    animation: shine-lines $table-skeleton-animation-duration infinite ease-out;
  }
}

@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 140px;
    }
}
