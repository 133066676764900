.form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $popup-bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  z-index: 2;

  > .card {
    margin-bottom: 0;

    @media (min-width: 1024px) {
      margin-top: 148px;
      margin-bottom: 60px;
    }

    @media (max-height: 680px) {
      .card-body {
        max-height: 380px
      }
    }

    @media (min-height: 680px) and (max-height: 840px) {
      margin-top: 80px;

      .card-body {
        max-height: 430px;
      }
    }
  }

  .card-body {
    max-width: 800px;
    max-height: 510px;
    overflow: auto;

    .form-submit-loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;

      display: flex;
      justify-content: center;
      align-items: center;

      background: rgba(255, 255, 255, 0.7);
    }
  }
}