$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    lgp: 1081px,
    xl: 1200px,
    xxl: 1441px
);

// Media query mixins
@mixin media-breakpoint-min($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        @media (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
            @content;
        }
    }
}

@mixin media-breakpoint-max($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        @media (max-width: #{map-get($grid-breakpoints, $breakpoint)}) {
            @content;
        }
    }
}

@mixin media-breakpoint-between($breakpoint1, $breakpoint2) {
    @if map-has-key($grid-breakpoints, $breakpoint1) {
        @media (min-width: #{map-get($grid-breakpoints, $breakpoint1)}) and (max-width: #{map-get($grid-breakpoints, $breakpoint2)})  {
            @content;
        }
    }
}

@keyframes input-updating-loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@mixin cell-loading-style() {
  color: $updating-timekeeping-input-color;
  background-color: $updating-timekeeping-input-bg-color;
}

@mixin input-loader-element() {
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: $updating-timekeeping-input-loader-size;
    height: $updating-timekeeping-input-loader-size;
    border-radius: 50%;
    border: 3px solid $updating-timekeeping-input-loader-border-color;
    border-top-color: #919191;
    animation: input-updating-loading 2s linear infinite;
  }
}

@mixin custom-scrollbar-style($isVertical: true) {
  &::-webkit-scrollbar {
    background-color: $scrollbar-bg-color;

    @if $isVertical {
      width: 7px;
    } @else {
      height: 7px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-bg-color;
  }
}

@mixin custom-border-right {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: $table-border-color;
  }
}