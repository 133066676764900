.projects-revenue-graph {

  .revenue-filters {

    @include media-breakpoint-max(md) {
      width: 100%;
    }

    .filter-choices-input {

      @include media-breakpoint-max(md) {
        width: 100%;
      }
    }
  }
}