.btn-without-style {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

	.bx {
		font-size: $checked-cross-icons-font-size;
	}
}