.flatpickr-calendar.animate {
  animation: unset !important;
}

.month-picker__container {
  z-index: 1;
}

.ql-editor strong {
  font-weight: 600;
}