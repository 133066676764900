@keyframes input-updating-loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.flatpickr-calendar.animate {
  animation: unset !important;
}

.month-picker__container {
  z-index: 1;
}

.ql-editor strong {
  font-weight: 600;
}

html {
  overflow-y: scroll;
}

.mt-3-2 {
  margin-top: 1.2rem !important;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-10-px {
  margin-right: 10px;
}

.mr-20-px {
  margin-right: 20px;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.required::after {
  content: " *";
  color: red;
}

.dummy-data {
  opacity: 0.2;
}

.notification-wrapper {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 999999;
}

.btn-icon.btn-sm.btn-small, .btn-group-sm > .btn-icon.btn.btn-small {
  height: calc(0.5rem + 1em + 2px);
  width: calc(0.5rem + 1em + 2px);
}

.color-red {
  color: #e70000;
}

.color-green {
  color: #00b700;
}

.cursor-pointer {
  cursor: pointer;
}

.width-auto {
  width: auto !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.without-shadow {
  box-shadow: unset !important;
}

.text-light-important {
  color: #fff !important;
}

.text-vertical {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.badge.badge-danger {
  background-color: #dc3545;
}

.w-xxs {
  min-width: 60px;
}

.display-flex {
  display: flex;
}
.display-flex.flex-end {
  justify-content: flex-end;
}
.display-flex.flex-bottom {
  align-items: flex-end;
}
.display-flex.flex-center {
  justify-content: center;
}

.text-bold {
  font-weight: 500;
}

.bg-unset {
  background: unset !important;
}

.pointer-events-none {
  pointer-events: none;
}

.visibility-hidden {
  visibility: hidden;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  width: 0px;
  opacity: 0;
}

.back-button.slide-fade-enter-to,
.back-button.slide-fade-leave-from {
  width: 84px;
}

.expand-transition {
  transition: width 0.3s;
  overflow: hidden;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-left-1 {
  border-left-width: 1px !important;
}

.border-right-1 {
  border-right-width: 1px !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}

.auth-page-wrapper .auth-one-bg {
  background-image: url("~@/assets/images/constro-team.jpg");
  background-position: center 78%;
}
.auth-page-wrapper .auth-one-bg .bg-overlay {
  background: linear-gradient(rgb(255, 255, 255) 60%, rgb(133, 133, 133));
  opacity: 0.7;
}

.data-fetch-progres {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 9999999999;
}
.data-fetch-progres .loading-anim {
  color: #1a1d21;
  font-size: 28px;
}
.data-fetch-progres .progress-bar {
  font-size: 16px;
  color: #fff;
}

.notification-body {
  max-height: 300px;
}

.notification-box {
  position: relative;
}

.notification-box-wrapper {
  top: calc(100% + 2px) !important;
  right: 0;
}

[data-layout=horizontal] .navbar-menu .navbar-nav .nav-item .nav-link {
  padding: 0.75rem 0.7rem !important;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-item.sub-menu > .nav-link {
  padding: 0.75rem 1.25rem 0.75rem 0.7rem !important;
}

[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link {
  color: #34353e !important;
}

[data-layout=horizontal] .navbar-nav .nav-item:hover > .nav-link {
  color: #D30201 !important;
}

[data-layout=horizontal] .navbar-menu.active {
  display: block;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0;
}
[data-layout=horizontal] .navbar-menu.active ul {
  display: block;
}

.dropzone-container {
  width: 100%;
}
.dropzone-container .dropzone {
  width: 100%;
}
.dropzone-container .dropzone .dropzone-icon {
  color: #878A99;
}
.dropzone-container .dropzone.active-dropzone .dropzone-icon {
  color: #FFFFFF;
}
.dropzone-container .dropzone.active-dropzone .dropzone-title {
  color: #FFFFFF;
}
.dropzone-container .dropzone .dropzone-label {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  margin-bottom: 0;
  background: transparent;
}
.dropzone-container .category-checkbox {
  margin-bottom: 0.4rem !important;
}
.dropzone-container .sub-category-row:last-child .category-checkbox {
  margin-bottom: 1rem !important;
}
.dropzone-container .files-list {
  width: 100%;
}
.dropzone-container .files-list .invoice-checkbox .form-check {
  margin-bottom: 0 !important;
}
.dropzone-container .row.product {
  padding: 0 8px;
  margin: 10px 0;
  --vz-gutter-x: unset;
  --vz-gutter-y: unset;
}

.form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  z-index: 2;
}
.form-wrapper > .card {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .form-wrapper > .card {
    margin-top: 148px;
    margin-bottom: 60px;
  }
}
@media (max-height: 680px) {
  .form-wrapper > .card .card-body {
    max-height: 380px;
  }
}
@media (min-height: 680px) and (max-height: 840px) {
  .form-wrapper > .card {
    margin-top: 80px;
  }
  .form-wrapper > .card .card-body {
    max-height: 430px;
  }
}
.form-wrapper .card-body {
  max-width: 800px;
  max-height: 510px;
  overflow: auto;
}
.form-wrapper .card-body .form-submit-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.custom-select {
  position: relative;
}
.custom-select .dropdown.without-padding {
  padding: 0 !important;
}

body .form-control.is-valid {
  border-color: #CED4DA;
  padding-right: 0.9rem;
  background-image: unset;
  background-size: unset;
  background-position: unset;
}
body .form-control.is-valid:focus {
  border-color: #CED4DA;
  box-shadow: unset;
}
body .form-check-input.is-valid {
  border-color: #CED4DA;
}
body .form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}
body .form-control.is-valid:focus {
  border-color: #CED4DA;
  box-shadow: unset;
}
body .invalid-feedback {
  display: block;
}

.receivers-list {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.receivers-select-column .receivers-select input[type=text] {
  padding-right: 96px;
}
.receivers-select-column .select-all-button {
  top: 28px;
  right: 8px;
}

.checkbox-without-label .form-check {
  margin-top: 2.1875rem;
}

.flatpickr-monthSelect-months {
  margin: 10px 1px 3px 1px;
  flex-wrap: wrap;
}

.flatpickr-monthSelect-month {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5px;
  justify-content: center;
  padding: 10px;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  width: 33%;
}

.flatpickr-monthSelect-month.flatpickr-disabled {
  color: #eee;
}

.flatpickr-monthSelect-month.flatpickr-disabled:hover,
.flatpickr-monthSelect-month.flatpickr-disabled:focus {
  cursor: not-allowed;
  background: none !important;
}

.flatpickr-monthSelect-theme-dark {
  background: #3f4458;
}

.flatpickr-monthSelect-theme-dark .flatpickr-current-month input.cur-year {
  color: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-prev-month,
.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-next-month {
  color: #fff;
  fill: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month {
  color: rgba(255, 255, 255, 0.95);
}

.flatpickr-monthSelect-month.today {
  border-color: #959ea9;
}

.flatpickr-monthSelect-month.inRange,
.flatpickr-monthSelect-month.inRange.today,
.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  background: #e6e6e6;
  cursor: pointer;
  outline: 0;
  border-color: #e6e6e6;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.inRange,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:focus {
  background: #646c8c;
  border-color: #646c8c;
}

.flatpickr-monthSelect-month.today:hover,
.flatpickr-monthSelect-month.today:focus {
  background: #959ea9;
  border-color: #959ea9;
  color: #fff;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background-color: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-monthSelect-month.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-monthSelect-month.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-monthSelect-month.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-monthSelect-month.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.endRange {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #80cbc4;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  vertical-align: top;
}

.flatpickr-monthSelect-months {
  margin-top: 3px;
}
.flatpickr-monthSelect-months .flatpickr-monthSelect-month {
  user-select: none;
}

.hidden-flat-pickr-input.is-invalid + .flatpickr-input {
  border-color: #ED5E5E;
}

input.flat-pickr-time-input {
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
input.flat-pickr-time-input::placeholder {
  color: #ababab;
}
input.flat-pickr-time-input.form-control {
  display: inline-block;
  padding: 0 2px;
}
input.flat-pickr-time-input.form-control.is-invalid {
  background-image: unset;
}

.form-label.range-input-label {
  margin-bottom: 2.6rem;
}

.custom-select .dropdown {
  overflow: auto;
}
.custom-select .dropdown ul li.assigned {
  cursor: auto;
  background-color: #EBEBEB;
  color: #6C757D;
}
.custom-select .dropdown ul li.assigned .disabled-text {
  color: #6C757D;
  font-size: 0.6rem;
  line-height: 0.5rem;
}

.dropdown.select-input-dropdown {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background: #fff;
  z-index: 1045;
  display: none;
  overflow: auto;
}
.dropdown.select-input-dropdown:not(.without-padding) {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.dropdown.select-input-dropdown.active {
  display: block;
}
.dropdown.select-input-dropdown ul {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 6px #e1e1e1;
  border-radius: 5px;
}
.dropdown.select-input-dropdown ul li {
  padding: 3px 1rem;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
.dropdown.select-input-dropdown ul li:hover {
  background-color: #f2f2f2;
}
.dropdown.select-input-dropdown ul li.selected {
  background-color: #f2f2f2;
}

.text-input-wrapper .dropdown.select-input-dropdown {
  position: absolute;
}

.btn-without-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.btn-without-style .bx {
  font-size: 1.2rem;
}

.user-avatar {
  align-items: center;
  background-color: #DE0110;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 0.825rem;
  font-weight: 600;
  justify-content: center;
  text-align: center;
}
.user-avatar.avatar-lg {
  font-size: 2rem;
}

.profile-template .profile-nav.nav-pills {
  padding: 8px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.1);
  margin-right: auto;
}
.profile-template .profile-nav.nav-pills .nav-link {
  color: #333;
}
.profile-template .profile-nav.nav-pills .nav-link::before {
  background-color: #333;
  opacity: 0.1;
}
.profile-template .edit-button-wrapper {
  margin-left: 2rem;
}

.invoice-field-indent {
  text-indent: 1rem;
}

@media (max-width: 768px) {
  .table-wrapper {
    overflow: auto;
  }
}
.filters-button.waves-effect {
  overflow: unset;
}
.filters-button .filters-counter {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  width: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.721875rem;
  background-color: #DE0110;
}

tr.archived {
  background-color: rgb(255, 205, 104);
  color: rgb(33, 37, 41);
}

tr.blacklisted {
  background-color: #DFDFDF;
  color: inherit;
}

.table-bordered tbody td {
  border-left-width: 1px;
  border-left-style: solid;
}
.table-bordered tbody td {
  border-right-width: 1px;
  border-right-style: solid;
}

.table-bordered-inner tbody td:not(:first-child):not(.border-right-2),
.table-bordered-inner tfoot td:not(:first-child):not(.border-right-2) {
  border-left-width: 1px;
  border-left-style: solid;
}
.table-bordered-inner tbody td:not(:last-child):not(.border-right-2),
.table-bordered-inner tfoot td:not(:last-child):not(.border-right-2) {
  border-right-width: 1px;
  border-right-style: solid;
}

.border-left-2 {
  border-left-width: 2px;
}

.border-right-2 {
  border-right-width: 2px;
}

.first-column-sticky th:first-child {
  background-color: var(--vz-table-bg);
}
.first-column-sticky td:first-child {
  background-color: #FFF;
}
.first-column-sticky th:first-child,
.first-column-sticky td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}
.first-column-sticky th:first-child::after,
.first-column-sticky td:first-child::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #E9EBEC;
}

.sticky-table .table-wrapper.overflow-auto td.sticky,
.sticky-table .table-wrapper.overflow-auto th.sticky {
  position: sticky;
  z-index: 1;
}
.sticky-table .table-wrapper.overflow-auto td.sticky {
  background-color: #FFF;
}

.bulk-checkbox-cell {
  text-align: center;
}
.bulk-checkbox-cell input[type=checkbox] {
  cursor: pointer;
  vertical-align: middle;
}

table.table-skeleton-loading {
  width: 100%;
  overflow: hidden;
}
table.table-skeleton-loading th {
  padding: 10px 5px;
  border-color: #CCC;
  background-color: #f0f0f0;
}
table.table-skeleton-loading td {
  padding: 10px 5px;
}
table.table-skeleton-loading th > div,
table.table-skeleton-loading td > div {
  width: 100px;
  height: 16px;
  margin: 6px auto;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px);
  background-size: 600px;
  animation: shine-lines 1s infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}
.timekeeping-form table th .weekday {
  font-weight: 400;
}
.timekeeping-form table th.is-weekend,
.timekeeping-form table td.is-weekend {
  background-color: #E5DFEC;
}
.timekeeping-form table th.is-today,
.timekeeping-form table td.is-today {
  border-left: 1px solid red;
  border-right: 1px solid red;
}
.timekeeping-form table th.is-night-shift input,
.timekeeping-form table td.is-night-shift input {
  background-color: yellow;
}
.timekeeping-form table th.is-updating .time-input-container,
.timekeeping-form table td.is-updating .time-input-container {
  position: relative;
}
.timekeeping-form table th.is-updating .time-input-container .flat-pickr-time-input,
.timekeeping-form table td.is-updating .time-input-container .flat-pickr-time-input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
}
.timekeeping-form table th.is-updating .time-input-container::after,
.timekeeping-form table td.is-updating .time-input-container::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.timekeeping-form table th.is-updating .absence-select input,
.timekeeping-form table td.is-updating .absence-select input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
}
.timekeeping-form table th.is-updating .absence-select::after,
.timekeeping-form table td.is-updating .absence-select::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.timekeeping-form table th.is-updating.work-hours .value, .timekeeping-form table th.is-updating.night-work-hours .value,
.timekeeping-form table td.is-updating.work-hours .value,
.timekeeping-form table td.is-updating.night-work-hours .value {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
  position: relative;
  border-radius: 4px;
}
.timekeeping-form table th.is-updating.work-hours .value::after, .timekeeping-form table th.is-updating.night-work-hours .value::after,
.timekeeping-form table td.is-updating.work-hours .value::after,
.timekeeping-form table td.is-updating.night-work-hours .value::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.timekeeping-form table th .time-placeholder,
.timekeeping-form table td .time-placeholder {
  color: #ababab;
}
.timekeeping-form table tr:first-child th.is-today {
  border-top: 1px solid red;
}
.timekeeping-form table tr:last-child td.is-today {
  border-bottom: 1px solid red;
}
.timekeeping-form table td.is-future {
  color: #B7B7B7 !important;
}
.timekeeping-form table td.is-future input {
  color: #B7B7B7;
}
.timekeeping-form .table-hover > tbody > tr:hover > td.is-weekend {
  --vz-table-accent-bg: $timekeeping-table-weekend-background;
}

.first-column-wide tr > td:first-child, .first-column-wide tr > th:first-child {
  white-space: nowrap;
}

.timekeeping-form .first-column-sticky thead th {
  border-top: 1px solid var(--vz-table-bg);
}
.timekeeping-form .first-column-sticky thead tr:first-child th:first-child::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--vz-table-bg);
}
.timekeeping-form .first-column-sticky tbody tr:last-child td:first-child::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #E9EBEC;
}

.month-picker-container {
  position: relative;
}
.month-picker-container .month-picker-input {
  padding: 1em 1.5em;
  font-size: 0.85em;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.month-picker-container .month-picker-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.month-picker-container .month-picker__container {
  position: absolute;
  top: calc(100% + 10px);
}

.main-content .modal-dialog-bg {
  position: fixed;
  top: 117px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.1);
  justify-content: center;
  display: none;
}
.main-content .modal-dialog-bg.active {
  display: flex;
}
.main-content .modal-dialog-bg .modal-dialog {
  margin-top: 0;
}
.main-content .modal-dialog-bg .modal-dialog .modal-content {
  border-radius: 0 0 5px 5px;
}
.main-content .modal-dialog-bg .modal-dialog .modal-content .warning-description {
  font-size: 1rem;
}
.main-content .modal-dialog-bg .modal-dialog .modal-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.main-content .modal-dialog-bg .loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.offcanvas.active {
  transform: translateX(0) !important;
  visibility: visible !important;
}

@media (max-width: 768px) {
  .projects-revenue-graph .revenue-filters {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .projects-revenue-graph .revenue-filters .filter-choices-input {
    width: 100%;
  }
}

.sidebar-work-card .form-label {
  color: var(--vz-body-color);
}
.sidebar-work-card .value-cell {
  position: relative;
  padding: 0.25rem;
}
.sidebar-work-card .value-cell input {
  padding: 0.25rem;
  text-align: center;
}
.sidebar-work-card .value-cell input::placeholder {
  text-align: center;
}
.sidebar-work-card .value-cell.is-updating::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.sidebar-work-card .value-cell.is-updating input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
  pointer-events: none;
}
.sidebar-work-card .value-cell.is-updating input::placeholder {
  color: transparent;
}
.sidebar-work-card .field.is-updating .number-input-wrapper {
  position: relative;
}
.sidebar-work-card .field.is-updating .number-input-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.sidebar-work-card .field.is-updating .number-input-wrapper input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
  pointer-events: none;
}
.sidebar-work-card .field.is-updating .number-input-wrapper input::placeholder {
  color: transparent;
}

.profile-data-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.profile-data-list li {
  display: flex;
  flex: 0 0 50%;
  margin-bottom: 0.5rem;
}
.profile-data-list li .label {
  flex: 0 0 190px;
  font-weight: 600;
  margin-right: 4px;
}

.card.schedule .card-header .month-picker-container {
  display: inline-block;
}
.card.schedule .card-header .month-picker-container .month-picker-value {
  cursor: pointer;
}
.card.schedule .card-header .month-picker-container .month-picker__container {
  z-index: 2;
}
.card.schedule .custom-select.absence-select .form-control.is-invalid {
  background-image: unset;
}
.card.schedule .custom-select.absence-select .invalid-feedback {
  display: none;
}

.timekeeping-form .overflow-auto::-webkit-scrollbar,
.absences-table .overflow-auto::-webkit-scrollbar,
.sidebar-work-card .overflow-auto::-webkit-scrollbar {
  background-color: #f3f3f3;
  height: 7px;
}
.timekeeping-form .overflow-auto::-webkit-scrollbar-thumb,
.absences-table .overflow-auto::-webkit-scrollbar-thumb,
.sidebar-work-card .overflow-auto::-webkit-scrollbar-thumb {
  background-color: #B2B2B2;
}

.schedule-title {
  line-height: 1.2;
  font-weight: 600;
  color: var(--vz-heading-color);
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
}

.comments-container {
  max-height: 400px;
}
.comments-container::-webkit-scrollbar {
  background-color: #f3f3f3;
  width: 7px;
}
.comments-container::-webkit-scrollbar-thumb {
  background-color: #B2B2B2;
}

.updating-badge {
  position: absolute;
  z-index: 100;
  right: 8px;
  bottom: 8px;
}

.tasks-histories .history-timeline .history-item-title {
  cursor: pointer;
}
.tasks-histories .history-timeline .history-item-title:hover {
  color: var(--vz-link-hover-color);
}

.task-sidebar .table tbody tr:first-child td {
  border-top-width: 1px;
}

.performed-table-date {
  font-size: 0.92rem;
  margin-right: 14px;
}
.performed-table-date i {
  margin-left: 4px;
}

.performed-table-container .overflow-auto table {
  position: relative;
}
.performed-table-container .overflow-auto table tr td,
.performed-table-container .overflow-auto table tr th {
  position: relative;
  padding: 0.625rem 0.75rem;
}
.performed-table-container .overflow-auto table tr td .number-input-wrapper input,
.performed-table-container .overflow-auto table tr td .text-input-wrapper input,
.performed-table-container .overflow-auto table tr th .number-input-wrapper input,
.performed-table-container .overflow-auto table tr th .text-input-wrapper input {
  padding: 0.25rem 0.125rem;
}
.performed-table-container .overflow-auto table tr td .text-input-wrapper input,
.performed-table-container .overflow-auto table tr th .text-input-wrapper input {
  width: 14rem;
}
.performed-table-container .overflow-auto table tr td.is-updating .number-input-wrapper,
.performed-table-container .overflow-auto table tr td.is-updating .text-input-wrapper,
.performed-table-container .overflow-auto table tr th.is-updating .number-input-wrapper,
.performed-table-container .overflow-auto table tr th.is-updating .text-input-wrapper {
  position: relative;
}
.performed-table-container .overflow-auto table tr td.is-updating .number-input-wrapper input,
.performed-table-container .overflow-auto table tr td.is-updating .text-input-wrapper input,
.performed-table-container .overflow-auto table tr th.is-updating .number-input-wrapper input,
.performed-table-container .overflow-auto table tr th.is-updating .text-input-wrapper input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
}
.performed-table-container .overflow-auto table tr td.is-updating .number-input-wrapper::after,
.performed-table-container .overflow-auto table tr td.is-updating .text-input-wrapper::after,
.performed-table-container .overflow-auto table tr th.is-updating .number-input-wrapper::after,
.performed-table-container .overflow-auto table tr th.is-updating .text-input-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.performed-table-container .overflow-auto table tr td.sticky::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #E9EBEC;
}
.performed-table-container .overflow-auto table tr th.sticky,
.performed-table-container .overflow-auto table tr td.sticky {
  border-right: 0;
  border-left: 0;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(1) .cell-extender,
.performed-table-container .overflow-auto table tr td.sticky:nth-child(1) .cell-extender {
  min-width: 120px;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(2) .cell-extender,
.performed-table-container .overflow-auto table tr td.sticky:nth-child(2) .cell-extender {
  min-width: 104px;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(3) .cell-extender,
.performed-table-container .overflow-auto table tr td.sticky:nth-child(3) .cell-extender {
  min-width: 160px;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(4) .cell-extender,
.performed-table-container .overflow-auto table tr td.sticky:nth-child(4) .cell-extender {
  min-width: 104px;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(5) .cell-extender,
.performed-table-container .overflow-auto table tr td.sticky:nth-child(5) .cell-extender {
  min-width: 100px;
}
.performed-table-container .overflow-auto table tr th.sticky .cell-extender,
.performed-table-container .overflow-auto table tr td.sticky .cell-extender {
  padding-left: 10px;
  padding-right: 10px;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(5)::after,
.performed-table-container .overflow-auto table tr td.sticky:nth-child(5)::after {
  width: 2px !important;
}
.performed-table-container .overflow-auto table tr th.sticky:nth-child(5)::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #E9EBEC;
}
.performed-table-container .overflow-auto table tfoot tr td {
  border-bottom: 0;
}
.performed-table-container .overflow-auto table tfoot tr td.sticky {
  left: 0;
}
.performed-table-container .overflow-auto table tfoot tr td.sticky::after {
  width: 2px !important;
}
.performed-table-container .overflow-auto::-webkit-scrollbar {
  background-color: #f3f3f3;
  height: 7px;
}
.performed-table-container .overflow-auto::-webkit-scrollbar-thumb {
  background-color: #B2B2B2;
}

.rotation-table-container:not(.table-bordered-inner) .expand-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky.border-right-2::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #E9EBEC;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky.border-right-2::after {
  width: 2px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky .cell-extender {
  width: 90px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #E9EBEC;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.border-right-2::after {
  width: 2px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky .text-input-wrapper input,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky .date-input-wrapper input {
  padding: 4px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.is-updating .text-input-wrapper,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.is-updating .date-input-wrapper {
  position: relative;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.is-updating .text-input-wrapper input,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.is-updating .date-input-wrapper input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.is-updating .text-input-wrapper::after,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky.is-updating .date-input-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky {
  padding: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky .cell-extender {
  padding: 0.75rem 0.3rem;
  min-width: 90px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky:nth-child(3) .cell-extender, .rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky:nth-child(4) .cell-extender, .rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky:nth-child(5) .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky:nth-child(3) .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky:nth-child(4) .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky:nth-child(5) .cell-extender {
  min-width: 120px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky:nth-child(6) .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky:nth-child(6) .cell-extender {
  min-width: 160px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky:nth-child(13) .cell-extender, .rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.sticky:nth-child(14) .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky:nth-child(13) .cell-extender,
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr th.sticky:nth-child(14) .cell-extender {
  min-width: 92px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.month-cell {
  position: relative;
  min-width: 60px;
  padding: 6px;
  cursor: pointer;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.month-cell.is-updating {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto table tr td.month-cell.is-updating::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto::-webkit-scrollbar {
  background-color: #f3f3f3;
  height: 7px;
}
.rotation-table-container:not(.table-bordered-inner) .overflow-auto::-webkit-scrollbar-thumb {
  background-color: #B2B2B2;
}

.rotation-select.select-input-dropdown::-webkit-scrollbar {
  background-color: #f3f3f3;
  width: 7px;
}
.rotation-select.select-input-dropdown::-webkit-scrollbar-thumb {
  background-color: #B2B2B2;
}
.rotation-select.select-input-dropdown ul li {
  padding: 0.1875rem 0.25rem;
  text-align: center;
}

.project-data-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.project-data-list li {
  display: flex;
  flex: 0 0 33%;
  margin-bottom: 0.5rem;
}
.project-data-list li .label {
  flex: 0 0 190px;
  font-weight: 600;
  margin-right: 4px;
}

.project-schedules .project-schedule-table tbody tr:last-child {
  border-bottom-color: var(--vz-border-color);
}
.project-schedules .field.col-md-12 {
  margin-bottom: 0 !important;
}
.project-schedules .field .number-input-wrapper .form-control {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0;
  padding-bottom: 0;
}
.project-schedules .field.is-updating .number-input-wrapper {
  position: relative;
}
.project-schedules .field.is-updating .number-input-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #CDCDCD;
  border-top-color: #919191;
  animation: input-updating-loading 2s linear infinite;
}
.project-schedules .field.is-updating .number-input-wrapper input {
  color: transparent;
  background-color: rgba(247, 247, 247, 0.8);
  pointer-events: none;
}
.project-schedules .field.is-updating .number-input-wrapper input::placeholder {
  color: transparent;
}

.timekeeping-form .card-body {
  overflow: visible;
}
.timekeeping-form .flat-pickr-time-input {
  background: transparent;
  text-align: center;
  max-width: 56px;
  outline: 0;
}
.timekeeping-form .flat-pickr-time-input:disabled {
  background: transparent;
  border-color: transparent;
  cursor: unset;
}
.timekeeping-form .absence-select {
  width: 56px;
  margin: auto;
}
.timekeeping-form .absence-select input {
  padding: 0;
  line-height: 1.7rem;
  text-align: center;
}
.timekeeping-form .absence-select input:disabled {
  background: transparent;
  border-color: transparent;
}

.table-light th {
  min-width: 70px;
}
.table-light th:last-child {
  min-width: 85px;
}
.table-light th.bulk-checkbox-cell {
  min-width: 20px;
}

.table-wrapper {
  overflow-x: auto;
}

.overflow-x {
  overflow-x: auto;
}

.project-data-list li {
  flex: 0 0 50%;
}

@media only screen and (max-width: 992px) {
  .project-data-list, .profile-data-list {
    overflow-x: auto;
  }
  .project-data-list li, .profile-data-list li {
    flex: 0 0 100%;
  }
  .flex-2-col {
    flex-wrap: wrap;
  }
  .edit-button-wrapper {
    margin-left: 0 !important;
    margin-top: 20px;
  }
  .mb-20px {
    margin-bottom: 20px !important;
  }
  .flex-2-col .no-margin,
  .no-margin {
    margin: 0 !important;
  }
  .margin-top {
    margin-top: 10px;
  }
}