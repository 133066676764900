.month-picker-container {
  position: relative;

  .month-picker-input {
    padding: 1em 1.5em;
    font-size: 0.85em;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .month-picker-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .month-picker__container {
    position: absolute;
    top: calc(100% + 10px);
  }
}