[data-layout=horizontal] .navbar-menu .navbar-nav .nav-item {

  .nav-link {
    padding: 0.75rem 0.7rem !important;
  }

  &.sub-menu > .nav-link {
    padding: 0.75rem 1.25rem 0.75rem 0.7rem !important;
  }
}

[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link {
  color: $menu-text-color !important;
}

[data-layout=horizontal] .navbar-nav .nav-item:hover > .nav-link {
  color: $main-red !important;
}

[data-layout=horizontal] .navbar-menu.active {
  display: block;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0;

  ul {
    display: block;
  }
}

