@import 'flatpickr/dist/plugins/monthSelect/style';

.flatpickr-months {

  .flatpickr-prev-month,
  .flatpickr-next-month {

    svg {
      vertical-align: top;
    }
  }
}

.flatpickr-monthSelect-months {
  margin-top: 3px;

  .flatpickr-monthSelect-month {
    user-select: none;
  }
}

.hidden-flat-pickr-input.is-invalid {

    &+ .flatpickr-input {
      border-color: $input-error-border-color;
    }
}