html {
  overflow-y: scroll;
}
.mt-3-2 { margin-top: 1.2rem !important; }

.ml-2 { margin-left: 0.5rem; }

.mr-10-px { margin-right: 10px; }
.mr-20-px { margin-right: 20px; }

.text-align-left { text-align: left }
.text-align-right { text-align: right }
.text-align-center { text-align: center }

.required {
  &::after {
    content: ' *';
    color: red;
  }
}

.dummy-data { opacity: 0.2 }

.notification-wrapper {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 999999;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  &.btn-small {
      height: calc(0.5rem + 1em + 2px);
      width: calc(0.5rem + 1em + 2px);
  }
}

.color-red { color: #e70000; }
.color-green { color: #00b700 }

.cursor-pointer { cursor: pointer }

.width-auto { width: auto !important; }
.line-height-1 { line-height: 1 !important; }
.without-shadow { box-shadow: unset !important; }
.text-light-important { color: #fff !important }

.text-vertical {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.badge.badge-danger {
  background-color: #dc3545;
}

.w-xxs {
  min-width: 60px;
}
.display-flex {
  display: flex;

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-bottom {
    align-items: flex-end;
  }

  &.flex-center {
    justify-content: center;
  }
}

.text-bold {
  font-weight: 500;
}

.bg-unset {
  background: unset !important;
}

.pointer-events-none {
  pointer-events: none;
}

.visibility-hidden {
  visibility: hidden;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  width: 0px;
  opacity: 0;
}

.back-button.slide-fade-enter-to,
.back-button.slide-fade-leave-from {
  width: 84px;
}

.expand-transition {
  transition: width 0.3s;
  overflow: hidden;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-left-1 {
  border-left-width: 1px !important;
}

.border-right-1 {
  border-right-width: 1px !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}
