.task-sidebar {

  .table {

    tbody {

      tr:first-child {

        td {
          border-top-width: $table-border-width;
        }
      }
    }
  }
}