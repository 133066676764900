.dropzone-container {
    width: 100%;

    .dropzone {
        width: 100%;

        .dropzone-icon {
            color: $color-light-gray;
        }

        &.active-dropzone {

            .dropzone-icon {
                color: $color-white;
            }

            .dropzone-title {
                color: $color-white;
            }
        }

        .dropzone-label {
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            margin-bottom: 0;
            background: transparent;
        }
    }

    .category-checkbox {
        margin-bottom: 0.4rem !important;
    }

    .sub-category-row {

        &:last-child {

            .category-checkbox {
                margin-bottom: 1rem !important;
            }
        }
    }

    .files-list {
        width: 100%;

        .invoice-checkbox {

            .form-check {
                margin-bottom: 0 !important;
            }
        }
    }

    .row.product {
        padding: 0 8px;
        margin: 10px 0;
        --vz-gutter-x: unset;
        --vz-gutter-y: unset;
    }
}
