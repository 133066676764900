.profile-template {

  .profile-nav.nav-pills {
    padding: 8px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    margin-right: auto;

    .nav-link {
      color: $profile-foreground-color;

      &::before {
        background-color: $profile-foreground-color;
        opacity: 0.1;
      }
    }
  }

  .edit-button-wrapper {
    margin-left: 2rem;
  }
}