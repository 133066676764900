.receivers-list {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.receivers-select-column {

  .receivers-select {

    input[type="text"] {
      padding-right: 96px;
    }
  }

  .select-all-button {
    top: 28px;
    right: 8px;
  }
}
