.timekeeping-form {

  table {

    th {
      .weekday {
        font-weight: $timekeeping-table-weekday-font-weight;
      }
    }

    th,
    td {
      &.is-weekend {
        background-color: $timekeeping-table-weekend-background;
      }

      &.is-today {
        border-left: 1px solid $timekeeping-table-today-border-color;
        border-right: 1px solid $timekeeping-table-today-border-color;
      }

      &.is-night-shift {

        input {
          background-color: $timekeeping-night-shift-input-bg-color;
        }
      }

      &.is-updating {

        .time-input-container {
          position: relative;

          .flat-pickr-time-input {
            @include cell-loading-style;
          }

          @include input-loader-element;
        }

        .absence-select {

          input {
            @include cell-loading-style;
          }

          @include input-loader-element;
        }

        &.work-hours,
        &.night-work-hours {
          .value {
            @include cell-loading-style;
            position: relative;
            border-radius: $time-input-border-radius;

            @include input-loader-element;
          }
        }
      }

      .time-placeholder {
        color: $time-input-placeholder-color;
      }
    }


    tr:first-child {
      th.is-today {
        border-top: 1px solid $timekeeping-table-today-border-color;
      }
    }

    tr:last-child {
      td.is-today {
        border-bottom: 1px solid $timekeeping-table-today-border-color;
      }
    }

    td.is-future {
      color: $timekeeping-table-future-color !important;

      input {
        color: $timekeeping-table-future-color;
      }
    }
  }

  .table-hover {

    & > tbody {

      & > tr:hover {

        & > td.is-weekend {
          --vz-table-accent-bg: $timekeeping-table-weekend-background;
        }
      }
    }
  }
}

.first-column-wide {

  tr {

    & > td:first-child,
    & > th:first-child {
      white-space: nowrap;
    }
  }
}

.timekeeping-form {

  .first-column-sticky {

    thead {

      th {
        border-top: 1px solid $table-head-bg-color;
      }

      tr:first-child {

        th:first-child {

          &::before {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $table-head-bg-color;
          }
        }
      }
    }

    tbody {
      tr:last-child {

        td:first-child {
          &::before {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $table-border-color;
          }
        }
      }
    }
  }
}