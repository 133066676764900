.auth-page-wrapper {

  .auth-one-bg {
    background-image: url('~@/assets/images/constro-team.jpg');
    background-position: center 78%;

    .bg-overlay {
      background: linear-gradient(rgba(255, 255, 255, 1) 60%, rgba(133, 133, 133, 1));
      opacity: 0.7;
    }
  }
}