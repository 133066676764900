.timekeeping-form {

  .card-body {
    overflow: visible;
  }

  .flat-pickr-time-input {
    background: transparent;
    text-align: center;
    max-width: 56px;
    outline: 0;

    &:disabled {
      background: transparent;
      border-color: transparent;
      cursor: unset;
    }
  }

  .absence-select {
    width: 56px;
    margin: auto;

    input {
      padding: 0;
      line-height: 1.7rem;
      text-align: center;

      &:disabled {
        background: transparent;
        border-color: transparent;
      }
    }
  }
}